import React, { useEffect, useState } from 'react'
import { MainWrap, Scroll } from '../../utilities/Shared'
import Gl40 from './GL40/Gl40'
import M110 from './M110'
import Digital2 from './digital/Digital2'
import Colormax from './digital/Colormax'
import { Tab, TabList, TabPanel, Tabs } from 'react-tabs'
import Bindery from './Bindery'
import axios from 'axios'
const Schedules = () => {
  const [activejobs, setActivejobs] = useState()
  const [jobids, setJobids] = useState([])
  const [employees, setEmployees] = useState([])

  useEffect(() => {
    const config = {
        headers: {
          'Content-Type': 'application/json',
          'Access-Control-Allow-Origin':'*' 
        }
      };
      let r = {
        job:"123456"
      }
  const getactive = async() => { 
    await axios.post(`https://highgroundsoftware.com/dash/dashboard/php/getactivejobs.php`,r,config)
.then(res => {
let u = []
res.data.map(item => {
u.push(item.job)
})
let ids = u
setActivejobs(res.data)
setJobids(u)
})
.catch(error => { console.log(error)})
  }
  const getemployees = async() => {
    //await axios.get(`https://highgroundapi.herokuapp.com/employee/active`)
    await axios.get(`https://highgroundapi.herokuapp.com/employee/active`)
.then(res => {setEmployees(res.data)})
.catch(error => { console.log(error)})
}
  getactive()
  getemployees()
  },[])




  return (
    <MainWrap style={{marginTop:"25px"}}>


<Tabs defaultIndex={localStorage.getItem('default01')}>
    <TabList >
    <Tab className="react-tabs__tab c" >GL40</Tab>
    <Tab className="react-tabs__tab c" >M110</Tab>
    <Tab className="react-tabs__tab c"  >Digital</Tab>
    <Tab  className="react-tabs__tab c" >Colormax</Tab>
    <Tab  className="react-tabs__tab c" >Stitching</Tab>
    <Tab  className="react-tabs__tab c" >Mailing</Tab>
  </TabList>

  <TabPanel ><Gl40 /></TabPanel>
  <TabPanel ><M110 /></TabPanel>
  <TabPanel ><Digital2 /></TabPanel>
  <TabPanel ><Colormax /></TabPanel>
  <TabPanel ><Bindery activejobs={activejobs} jobids={jobids} employeelist={employees} /></TabPanel>
</Tabs>


<br /><br /><br /><br /><br />
    </MainWrap>
  )
}

export default Schedules