import React, { useRef,useEffect ,useState, useContext} from "react";
import Moment from 'react-moment';

import LOGO from '../Assets/plsprint.png'
import {AiFillPrinter} from 'react-icons/ai';
import styled from 'styled-components'
import ReactToPrint from "react-to-print";
import TextareaAutosize from 'react-textarea-autosize';
import { Button, Img, Label, Selectbox, currency } from "../utilities/Shared";
import { colorchange, employeeConvert } from "../utilities/Convertions";
import axios from "axios";
import UserContext from "../Context/UserContext";
import { getemployeebynamefromempid } from '../utilities/Convertions'

export const Wrapper = styled.div`
padding:20px 40px;
font-size:.8rem;

text-transform:capitalize;
font-family: Arial, Helvetica, sans-serif;
`;
export const Input = styled.input`

position: ${props => props.position || "relative"};

width:${props => props.width || "90%"};
padding:${props => props.padding || "5px 3px 0px 3px"};
max-width:${props => props.maxWidth || "100%"};
margin:${props => props.margin || "0px 0px 0px 0px"};
height: ${props => props.height || ""};
font-size:${props => props.fontSize || ".8rem"};
text-align: ${props => props.textalign || "center"};
font-weight: ${props => props.fontWeight || "bold"};

outline:none;
border:none;
border-bottom:1px solid grey;
border-radius:5px;
:focus{
  border:2px solid var(--plsGreen);
}
`;
export const Flexed = styled.div`
width:${props => props.width || "100%"};
gap:${props => props.gap || ""};
display:flex;
justify-content:${props => props.justifyContent || "flex-end"};
align-items:${props => props.alignItems || "flex-start"};
position: ${props => props.position || "relative"};
padding: ${props => props.padding || "3px 0px"};
bottom: ${props => props.bottom || ""};
font-size:${props => props.fontSize || ""};
margin:${props => props.margin || ""};
border-bottom: ${props => props.borderBottom || ""};
border-top: ${props => props.borderTop || ""};
border: ${props => props.border || ""};
background-color: ${props => props.bgColor || ""};
`;

export const Flex = styled.div`
width:${props => props.width || "100%"};
position: ${props => props.position || "relative"};
flex:${props => props.flex || "1"};
white-space: ${props => props.whiteSpace || "nowrap"};
flex: ${props => props.flex || "1"};
border-bottom: ${props => props.border || ""};
text-align:${props => props.textAlign || "left"};
padding: ${props => props.padding || "0"};
font-size:${props => props.fontSize || ""};
border-bottom: ${props => props.borderBottom || ""};
align-items:${props => props.alignItems || "flex-start"};
flex-wrap:wrap;

h2{
    margin:0;
    padding:0:
}
`;



export const Textarea = styled(TextareaAutosize)`
font-size:1.4rem;
width:100%;
border:none;
padding:10px 10px 40px 10px;
resize:none;
:focus{
    border:2px solid var(--plsGreen);
  }

`;
export const Headerspan = styled.div`
width:100%;
text-align:center;
font-size:1.1rem;
`;


export default function PrintComponent({customerinfo,jobinfo,forms,presses,methods,imposition,po,osp,osppo}) {
  let componentRef = useRef();
const [message, setMessage] = useState("")
const [total, setTotal] = useState(0)
const date = new Date()
const { employees } = useContext(UserContext)

console.log(forms)
useEffect(() => {
  
  customerinfo.salesrepname = getemployeebynamefromempid(customerinfo.salesrep,employees)
  customerinfo.csrname = getemployeebynamefromempid(customerinfo.csr,employees)
},[customerinfo])



console.log(customerinfo)

  function percentage(partialValue, totalValue) {
    const t = (partialValue / 100) * totalValue
    return t + totalValue
 }      




  return (
    <>
      <Flex id="print_component" style={{textAlign:"right",paddingTop:"15px"}}>
        {/* button to trigger printing of target component */}
        <ReactToPrint
      
          trigger={() => <Button bgcolor="buttonface" padding="8px 25px" fontSize="calc(1.1rem + .3vw)" border="1px solid Black">Print Ticket</Button>}
          content={() => componentRef}
        />

        {/* component to be printed */}
        <Flex style={{ display: "none" }}>
          <ComponentToPrint date={date}  osp={osp} osppo={osppo} customerinfo={customerinfo} po={po} imposition={imposition} jobinfo={jobinfo} presses={presses} methods={methods} forms={forms} ref={(el) => (componentRef = el)} />
        </Flex>
      </Flex>
    </>
  );
}




// component to be printed
class ComponentToPrint extends React.Component {

  constructor(props) {
    super(props);

  }

  render() {
    return (
      
     <Wrapper style={{background:"white"}}>

<Flexed margin="25px 0px 0px 0px">
<Flex flex="1.6" style={{fontSize:"2.8rem",textAlign:"left",top:"10px",fontStyle:"italic",padding:"7px 150px 7px 20px"}}>Production Ticket</Flex>
<Flex flex="auto" textAlign="right"><Img src={LOGO} alt="Logo" style={{width:"180px",left:"0px",}}/></Flex>
    <Flex padding="0px 0px 0px 30px" fontSize="1.4rem" textAlign="right">Generated <Moment style={{fontWeight:"bold",fontSize:"1.4rem"}} format="MM/DD/YYYY">{this.props.date}</Moment><br />at {this.props.date.getHours()}:{this.props.date.getMinutes()} by<br />{sessionStorage.getItem('fullName')}</Flex>
  
</Flexed>

<Flexed style={{marginTop:"15px"}}>
    <Flex  whiteSpace="wrap" flex="2" style={{fontSize:"1.4rem",paddingTop:"10px"}}>Customer<br/><span style={{fontSize:"3.5rem",fontWeight:"bold"}}>{this.props.customerinfo?.customer?.replace(/&amp;/g, "&")}</span></Flex>
    <Flex style={{fontSize:"1.4rem",textAlign:"left",paddingTop:"10px"}}>Date Due<br/><Moment format="MM/DD/YYYY" style={{fontSize:"3.7rem"}}>{this.props.customerinfo?.duedate}</Moment></Flex>
    <Flex flex=".4" style={{fontSize:"6.7rem",fontWeight:"bold"}}>{this.props.customerinfo?.job}</Flex>
</Flexed>

<Flexed border="1px solid black" padding="5px 0px">
<Flex  flex="1" style={{fontSize:"1.4rem",paddingLeft:"10px",borderRight:"1px solid black"}}>Salesperson<br/><span style={{fontSize:"1.8rem",fontWeight:"bold",paddingLeft:"10px"}}>{this.props.customerinfo?.salesrepname === "Kelly A" ? "HOT HOT HOT" : this.props.customerinfo?.salesrepname}</span></Flex>
<Flex  flex="1" style={{fontSize:"1.4rem",paddingLeft:"10px",borderRight:"1px solid black"}}>CSR<br/><span style={{fontSize:"1.8rem",fontWeight:"bold",paddingLeft:"10px"}}>{this.props.customerinfo?.csrname === "Kelly A" ? "HOT HOT HOT" : this.props.customerinfo?.csrname}</span></Flex>
<Flex  flex="1" style={{fontSize:"1.4rem",paddingLeft:"10px",borderRight:"1px solid black"}}>Est#<br/><span style={{fontSize:"1.8rem",fontWeight:"bold",paddingLeft:"10px"}}>{this.props.customerinfo?.quote}</span></Flex>
<Flex  flex="1" style={{fontSize:"1.4rem",paddingLeft:"10px",borderRight:"1px solid black"}}>Job Type<br/><span style={{fontSize:"1.8rem",fontWeight:"bold",paddingLeft:"10px"}}>{this.props.customerinfo?.jobtype}</span></Flex>
<Flex  flex="1" style={{fontSize:"1.4rem",paddingLeft:"10px",borderRight:"1px solid black"}}>Prev Job#<br/><span style={{fontSize:"1.8rem",fontWeight:"bold",paddingLeft:"10px"}}>{this.props.customerinfo?.prevjob}</span></Flex>
<Flex  flex="1" style={{fontSize:"1.4rem",paddingLeft:"10px",borderRight:"1px solid black"}}>PO#<br/><span style={{fontSize:"1.8rem",fontWeight:"bold",paddingLeft:"10px"}}>{this.props.customerinfo?.custpo}</span></Flex>
<Flex  flex="1" style={{fontSize:"1.4rem",paddingLeft:"10px"}}>Date Entered<br/><span style={{fontSize:"1.8rem",fontWeight:"bold",paddingLeft:"10px"}}><Moment format="MM/DD/YYYY" style={{fontSize:"1.8rem",paddingLeft:"10px"}}>{this.props.customerinfo?.dateentered}</Moment></span></Flex>
</Flexed>


<Flexed>
    <Flex style={{fontSize:"1.4rem",paddingLeft:"10px",borderRight:"1px solid black"}}>Quantity<br /><span style={{fontSize:"3rem",fontWeight:"bold",paddingLeft:"10px"}}>{this.props.customerinfo?.quantity}</span></Flex>
    <Flex flex="5" style={{fontSize:"1.4rem",paddingLeft:"10px",borderRight:"1px solid black"}} whiteSpace="normal">Job Name <br /><span style={{fontSize:"3rem",fontWeight:"bold",paddingLeft:"10px"}}>{this.props.customerinfo?.jobname}</span></Flex>
</Flexed>

<Flexed border="1px solid black" style={{backgroundColor:"rgb(0,0,0,.1)"}}>
    <Flex style={{fontSize:"1.4rem",paddingLeft:"10px",fontWeight:"bold",padding:"1.5px"}}>Description</Flex>
</Flexed>
<Flexed gap="10px" padding="10px" border="1px solid black" >
    <Flex><div style={{fontSize:"1.4rem",width:"100%",whiteSpace:"pre-wrap",padding:"0px 20px"}}>{this.props.customerinfo?.pnotes?.replace(/&amp;/g, "&")}</div></Flex>
    <Flex><div style={{fontSize:"1.4rem",width:"100%",whiteSpace:"pre-wrap",padding:"0px 20px"}}>{this.props.customerinfo?.desc2?.replace(/&amp;/g, "&")}</div> </Flex>
    <Flex><div style={{fontSize:"1.4rem",width:"100%",whiteSpace:"pre-wrap",padding:"0px 20px"}}>{this.props.customerinfo?.desc3?.replace(/&amp;/g, "&")}</div> </Flex>
</Flexed>

<div style={{border:"1px solid rgb(0,0,0, .3)"}}>
<Flexed border="1px solid black" style={{backgroundColor:"rgb(0,0,0,.1)",marginTop:"15px"}}>
    <Flex style={{fontSize:"1.4rem",paddingLeft:"10px",fontWeight:"bold",padding:"1.5px"}}>Pre-Press</Flex>
</Flexed>
<Flexed gap="10px" padding="10px" border="1px solid black" style={{borderTop:"0px",}}>
    <Flex><Textarea style={{border:"1px solid red"}} value={this.props.customerinfo?.prepressnotes?.replace(/&amp;/g, "&")} /></Flex>
</Flexed>

</div>


{/*/////////////////////////////////////////////////PRESS////////////////////////////////////////////////////////////////////////////////////////*/}

<div style={{border:"1px solid rgb(0,0,0, .3)"}}>
<Flexed border="1px solid black" style={{backgroundColor:"rgb(0,0,0,.1)"}}>
    <Flex style={{fontSize:"1.4rem",paddingLeft:"10px",fontWeight:"bold",padding:"1.5px"}}>Press Components</Flex>
</Flexed>
{this.props.imposition.map((item,index) => {
return <>
<div style={{pageBreakBefore:"auto",pageBreakAfter:"auto"}} className="normalrows">
  {this.props.forms.filter(filt => filt.item == item.id).map((newitem,index) => {
return <>
<div className="normalrows">
   <Flexed padding="5px" >
  <Flex top="21px" fontSize="1.4rem" fontWeight="bold" flex="1" >{newitem.name}</Flex>
  <Flex  fontSize="1.4rem" >{newitem.versions} Ver(s)</Flex>
  <Flex  fontSize="1.4rem" >{newitem.pages} Page(s)</Flex>
  <Flex  fontSize="1.4rem" >{newitem.numup} Up</Flex>
 
  <Flex fontSize="1.4rem">{this.props.methods.filter(filt => filt.name === newitem.method).map(item => {
           return <>{item.name}</>
           })
}</Flex>


    <Flex flex=".7" fontSize="1.4rem">{this.props.presses.filter(filt => parseInt(filt.id) === parseInt(newitem.pressid)).map(item => {
           return <>{item.name}</>
           })
}</Flex>

  </Flexed>
  <Flexed  padding="8px"  >
 <Flex  flex="1.8" fontSize="1.4rem" >{newitem.paper} </Flex>
 
    
      <Flex flex="1" fontSize="1.4rem" padding="0px 5px 0px 0px">Front:{newitem.fink} {newitem.faq > "" ? `+ ${newitem.faq}` : ""}</Flex>
      <Flex flex=".3"></Flex>
     <Flex flex="1" padding="0px 5px 0px 0px" fontSize="1.4rem">Back:{newitem.bink} {newitem.baq > "" ? `+ ${newitem.baq}` : ""}</Flex>
     <Flex flex=".3"></Flex>
     </Flexed>
  <Flexed borderBottom="1px solid grey">
  <Flex flex="1.2" fontSize="1.4rem">
      {newitem.length} X {newitem.width}
      </Flex>
    <Flex flex=".8"></Flex>
     <Flex flex="1"  fontSize="1.4rem" textAlign="center">To Press: {Math.ceil(newitem.topress.to)} </Flex>
       <Flex flex="1"  fontSize="1.4rem" textAlign="center">M/R: {Math.ceil(newitem.topress.mr)}</Flex>
       <Flex flex="1"  fontSize="1.4rem" textAlign="center">Off Press: {Math.ceil(newitem.topress.off)} </Flex>
       <Flex flex=".3"></Flex>
       <Flex flex="1"  borderBottom="1px solid black" fontSize="1.4rem" textAlign="left">Yield: </Flex>
     </Flexed>
</div>
</>
}
  )}
 
  </div>

</>

}
)}
  <Flexed gap="10px" borderBottom="1px solid black">
    <Flex><Textarea  value={this.props.customerinfo?.pressnotes?.replace(/&amp;/g, "&") || "No notes currently"} /></Flex>
</Flexed>
{/*/////////////////////////////////////////////////STOCK////////////////////////////////////////////////////////////////////////////////////////*/}
<div style={{pageBreakAfter:"auto",pageBreakBefore:"auto",padding:"0px 5px"}}>
<Flexed padding="7px 0px"  bgColor="rgb(0,0,0,.1)"  margin="20px 0px 0px 0px" >
    <Flex style={{fontSize:"1.4rem",paddingLeft:"10px",fontWeight:"bold",padding:"1.5px"}}>Stock</Flex>  
    <Flex fontSize="1.4rem" padding="0px 10px 0px 0px">Stock Status: {this.props.customerinfo?.allocated}</Flex>
    <Flex fontSize="1.4rem">Date:  <Moment style={{fontSize:"1.4rem"}} format="MM/DD/YYYY">{new Date(this.props.customerinfo?.allocatedate)}</Moment>
   </Flex>
   <Flex fontSize="1.4rem">Pull / Cut:  {this.props.customerinfo?.pullcut}
   </Flex>
</Flexed>


<Flexed bgColor="#92A4AB" padding="4px 0px">
  <Flex fontSize="1.4rem" flex="3" >Stock</Flex>
  <Flex fontSize="1.4rem"> ID</Flex>
  <Flex fontSize="1.4rem">LBS</Flex>
  <Flex fontSize="1.4rem">PO #</Flex>
  <Flex fontSize="1.4rem" >Sheets</Flex>
  <Flex fontSize="1.4rem">#Out</Flex>
  <Flex fontSize="1.4rem" flex="1.8">Size</Flex>
  <Flex fontSize="1.4rem" flex="2">Supplier</Flex>
  
  <Flex fontSize="1.4rem">Ordered</Flex>
  <Flex fontSize="1.4rem">Due</Flex>

</Flexed>
{this.props.imposition.map(item => {
  console.log(item)
return <Flexed padding="4px 0px" style={{borderBottom:".5px solid black"}}>
<Flex fontSize="1.3rem" flex="3" whiteSpace="normal">{item.paper}</Flex>
<Flex fontSize="1.3rem">{item.paperid}</Flex>
<Flex fontSize="1.3rem">{item.pounds}</Flex>
<Flex fontSize="1.3rem">{this.props.po?.map(pur => {return <>{pur.stockid > 0  ? pur?.ponum : ""}<br /></>})}</Flex>
<Flex fontSize="1.3rem" >{Math.ceil(parseInt(item.tottopress)) / parseInt(item.parentout)}</Flex>
<Flex fontSize="1.3rem">{item.parentout}</Flex>
<Flex fontSize="1.3rem" flex="1.8">{item.parentsheetl} X {item.parentsheetw}</Flex>
<Flex fontSize="1.2rem" top="8px" flex="2">{this.props.po?.filter(filt => filt.stockid === item.paperid).map(it => {return it.vendor})}</Flex>


<Flex fontSize="1.3rem">{this.props.po?.filter(filt => filt.stockid === item.paperid).map(it => {return <Moment format="MM/DD/YY" style={{fontSize:"1.3rem"}}>{it.createdAt}</Moment>})}</Flex>
<Flex fontSize="1.3rem">{this.props.po?.filter(filt => filt.stockid === item.paperid).map(it => {return <Moment format="MM/DD/YY" style={{fontSize:"1.3rem"}}>{it.duedate}</Moment>})}</Flex>
{/*<Flex fontSize="1.3rem">{this.props.po?.map(purr => purr.items.filter(fil => parseInt(fil.stockid) === parseInt(item.paperid) && parseInt(fil.jobnum) === parseInt(this.props.customerinfo?.job)).map(it => {return it.recievedstatus ? "Arrived" : "Awaiting"}))}</Flex>*/}
</Flexed>

})
}
<Flexed gap="10px" padding="10px" borderBottom="1px solid black">
    <Flex><Textarea  value={this.props.customerinfo?.papernotes?.replace(/&amp;/g, "&") || "No notes currently"} /></Flex>
</Flexed>
</div>

{/*/////////////////////////////////////////////////Bindery////////////////////////////////////////////////////////////////////////////////////////*/}

</div>
<div div style={{pageBreakInside:"avoid"}} >
<Flexed padding="7px 0px"  bgColor="rgb(0,0,0,.2)"  margin="20px 0px 0px 0px">
    <Flex style={{fontSize:"1.4rem",paddingLeft:"10px",fontWeight:"bold",padding:"1.5px"}}>Bindery</Flex>  <Flex display="flex" justifyContent="flex-end"></Flex>
</Flexed>


{this.props.imposition.map((item,index) => {
return <>
<Flexed bgColor="rgb(14,66,90,.4)" padding="3px" alignItems="center" >
  <Flex fontSize="1.4rem" fontWeight="bold" flex="7.5">{item.component} &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp;{item.numforms} Forms&nbsp; - &nbsp;{item.versions} version &nbsp;&nbsp; {item.pages}Pg / &nbsp;&nbsp; {item.numforms} - {item.pages / item.numforms} page(s) </Flex>
  {parseInt(item.numforms) > 1 &&   <>
       <Flex  fontSize="1.4rem">To Bindery: {(parseInt(item.needed) + parseInt(item.additionalmr) + parseInt(item.mroverride) - parseInt(item.mroverride)) * parseInt(item.versions)}</Flex></>}
   </Flexed>
  {this.props.forms.filter(filt => filt.item == item.id).map((newitem,index) => {
return <>
   <Flexed  padding="3px" style={{marginBottom:"15px",borderBottom:"1px solid grey"}}>
  <Flex top="21px" fontSize="calc(1rem + .2vw)" fontWeight="bold" flex="1" >{newitem.name}</Flex>
  <Flex  fontSize="1.4rem" flex=".3">Pages:<br /> {parseInt(newitem.pages)}</Flex>
    <Flex  fontSize="1.4rem" flex=".3">Forms:<br /> {parseInt(newitem.numforms)}</Flex>
    <Flex  fontSize="1.4rem" flex=".3">Ver's:<br /> {newitem.versions}</Flex>
       <Flex fontSize="1.4rem">  Sheet Size:<br />{newitem.length} X {newitem.width}</Flex>
       <Flex fontSize="1.4rem">Piece Size:<br />{item.finishsizel} X {item.finishsizew}</Flex>
      <Flex  fontSize="1.4rem" flex=".3">Cuts:<br /> {parseInt(item.cuts)}</Flex>
      <Flex  fontSize="1.4rem" >Fold Up:<br />{parseInt(item.foldsup)} </Flex>
      <Flex  fontSize="1.4rem" >Fold Style:<br />{item.foldstyle} </Flex>
      <Flex  fontSize="1.4rem" flex=".5">Die Cut:<br /> {parseInt(item.diecut) == 1 ? "Yes" : "No"}</Flex>
       
  </Flexed>
</>
}
  )}
</>
}
)}
<div style={{pageBreakInside:"avoid"}}>
<Flexed style={{marginTop:"-5px"}}>
<Textarea value={this.props.customerinfo?.binderynotes?.replace(/&amp;/g, "&")} placeholder="Bindery Notes" minRows="1" rows="1"/>
</Flexed>
</div>
</div>

<Flexed gap="20px" style={{marginTop:"25px",pageBreakInside:"avoid"}}>
  <Flex flex=".6">
    <Flexed border="1px solid black">
      <Flex fontSize="1.4rem" style={{borderRight:"1px solid black"}} flex=".6" padding="5px 0px 5px 5px" >Customer<br />Samples</Flex>
      <Flex fontSize="1.4rem" style={{display:"flex",alignItems:"center",paddingLeft:"5px"}}>5 Samples</Flex>
    </Flexed>
    <Flexed border="1px solid black">
      <Flex fontSize="1.4rem" style={{borderRight:"1px solid black"}} flex=".6" padding="5px 0px 5px 5px" >Job Ticket<br />Samples</Flex>
      <Flex fontSize="1.4rem"  style={{display:"flex",alignItems:"center",paddingLeft:"5px"}}>5 Samples</Flex>
    </Flexed>
    <Flexed border="1px solid black">
      <Flex fontSize="1.4rem" style={{borderRight:"1px solid black"}} flex=".6" padding="5px 0px 5px 5px" >Bundle In</Flex>
      <Flex fontSize="1.4rem"  style={{display:"flex",alignItems:"center",paddingLeft:"5px"}}></Flex>
    </Flexed>
    <Flexed border="1px solid black">
      <Flex fontSize="1.4rem" style={{borderRight:"1px solid black"}} flex=".6" padding="5px 0px 5px 5px" >Bind On</Flex>
      <Flex fontSize="1.4rem" style={{display:"flex",alignItems:"center",paddingLeft:"5px"}}></Flex>
    </Flexed >
    <Flexed border="1px solid black">
      <Flex fontSize="1.4rem" style={{borderRight:"1px solid black"}} flex=".6" padding="5px 0px 5px 5px" >Drilling</Flex>
      <Flex fontSize="1.4rem"  style={{display:"flex",alignItems:"center",paddingLeft:"5px"}}></Flex>
    </Flexed>
    <Flexed border="1px solid black">
      <Flex fontSize="1.4rem" style={{borderRight:"1px solid black"}} flex=".6" padding="5px 0px 5px 5px" >Coiling / Wire O</Flex>
      <Flex fontSize="1.4rem"  style={{display:"flex",alignItems:"center",paddingLeft:"5px"}}></Flex>
    </Flexed>
    <Flexed border="1px solid black">
      <Flex fontSize="1.4rem" style={{borderRight:"1px solid black"}} flex=".6" padding="5px 0px 5px 5px" >Handwork</Flex>
      <Flex fontSize="1.4rem"  style={{display:"flex",alignItems:"center",paddingLeft:"5px"}}></Flex>
    </Flexed>
  </Flex>


  <Flex >
    <Flexed border="1px solid black" >
      <Flex  fontSize="1.4rem" style={{backgroundColor:"rgb(0,0,0,.2)"}}  padding="5px 0px 5px 5px" >Bindery Counts</Flex>
    </Flexed>
    <Flexed border="1px solid black">
      <Flex fontSize="1.4rem" padding="5px 0px 5px 5px" style={{borderRight:"1px solid black"}}>Form</Flex>
      <Flex fontSize="1.4rem" padding="5px 0px 5px 5px" style={{borderRight:"1px solid black"}}>Cutting</Flex>
      <Flex fontSize="1.4rem" padding="5px 0px 5px 5px" style={{borderRight:"1px solid black"}}>Folding</Flex>
      <Flex fontSize="1.4rem" padding="5px 0px 5px 5px">Stitching</Flex>
    </Flexed>
    {
  this.props.forms.map(ite => {
   return   <Flexed border="1px solid black">
   <Flex fontSize="1.4rem" padding="5px 0px 5px 5px" style={{borderRight:"1px solid black",display:"flex",alignItams:"center"}}>{ite.name}</Flex>
   <Flex fontSize="1.4rem"  padding="5px 0px 5px 5px" style={{borderRight:"1px solid black"}}>&nbsp;</Flex>
   <Flex fontSize="1.4rem"  padding="5px 0px 5px 5px" style={{borderRight:"1px solid black"}}>&nbsp;</Flex>
   <Flex fontSize="1.4rem" padding="5px 0px 5px 5px">&nbsp;</Flex>
 </Flexed>
  })
}
  </Flex>
</Flexed>




<Flexed padding="7px 0px"  bgColor="rgb(0,0,0,.2)"  margin="20px 0px 0px 0px">
    <Flex style={{fontSize:"1.4rem",paddingLeft:"10px",fontWeight:"bold",padding:"1.5px"}}>Mailing</Flex>  <Flex display="flex" justifyContent="flex-end"></Flex>
</Flexed>
<Flexed bgColor="#92A4AB" padding="4px 0px">
  <Flex fontSize="1.4rem" flex="2.5">Mail Processing</Flex>
  <Flex fontSize="1.4rem"> Ink Jet</Flex>
  <Flex fontSize="1.4rem">Tabbing</Flex>
  <Flex fontSize="1.4rem">Inserting</Flex>
  <Flex fontSize="1.4rem">Mail Match</Flex>
  <Flex fontSize="1.4rem">Scratch-Off</Flex>
  <Flex fontSize="1.4rem">Political</Flex>
</Flexed>


<Flexed padding="7px 0px"  bgColor="rgb(0,0,0,.2)"  margin="20px 0px 0px 0px">
    <Flex style={{fontSize:"1.4rem",paddingLeft:"10px",fontWeight:"bold",padding:"1.5px"}}>Outside Services</Flex>  <Flex display="flex" justifyContent="flex-end"></Flex>
</Flexed>
<div style={{border:"1px solid rgb(0,0,0,.5)"}}>
<Flexed bgColor="#92A4AB" padding="4px 0px">
<Flex fontSize="1.2rem" fontWeight="bold" flex="4"  >Description</Flex>
  <Flex fontSize="1.2rem" fontWeight="bold" >Quantity</Flex>
  <Flex fontSize="1.2rem" fontWeight="bold" flex="2" >Vendor</Flex>
  <Flex fontSize="1.2rem" fontWeight="bold" >Vendor Quote</Flex>
  <Flex fontSize="1.2rem" fontWeight="bold" flex=".5">PO #</Flex>
  <Flex fontSize="1.2rem" fontWeight="bold" flex=".5">Quote #</Flex>
</Flexed>
{this.props.customerinfo?.osp?.map(item => {
return <Flexed padding="7px 0px" borderBottom="1px solid black">
    <Flex fontSize="1.1rem" flex="4" whiteSpace="pre-wrap">{item.description}</Flex>
<Flex fontSize="1.2rem" >
{item.quan1}
</Flex>
<Flex fontSize="1.2rem" flex="2" whiteSpace="pre-wrap">{item.vendor}</Flex>
<Flex fontSize="1.2rem" >{item.cusquote}</Flex>
<Flex fontSize="1.2rem" flex=".5">{this.props?.ospPo?.filter(filt => filt.vendor === item.vendor).map(it => {return 'blah'})}</Flex>

<Flex fontSize="1.2rem" flex=".5">{item.quote}</Flex>


</Flexed>

})
}

<Flexed >
  <Flex>
<Textarea  value={this.props.customerinfo?.ospnotes?.replace(/&amp;/g, "&")} /></Flex>

</Flexed>
</div>

{/*//////////////////////////////////////////////////////SHIPPING///////////////////////////////////////////////////////////////////////////////*/}
<div style={{fontSize:"1.4rem",padding:"7px 0px",bgColor:"rgb(0,0,0,.2)",margin:"20px 0px 0px 0px"}}>
  
<Flexed padding="7px 0px"  bgColor="rgb(0,0,0,.2)">
    <Flex style={{fontSize:"1.4rem",paddingLeft:"10px",fontWeight:"bold",padding:"1.5px"}}>Shipping</Flex>  <Flex display="flex" justifyContent="flex-end"></Flex>
</Flexed>
{
this.props.customerinfo?.shippingitems?.map((item,index) => {
  console.log(item)
  return<div  style={{border:"1px solid rgb(0,0,0,.3)",marginBottom:"10px"}}>
  <Flexed padding="5px" bgColor="rgb(0,0,0,.3)">
  <Flex fontSize="calc(1.1rem + .2vw)">{item.locationname} - {item.address1 ? item.address1 : ""} {item.address2 ? item.address2 : ""} {item.city ? item.city : ""} {item.state ? item.state : ""} {item.zip ? item.zip : ""}</Flex>
</Flexed>
<Flexed style={{borderBottom:"1px solid black"}}>
  <Flex fontSize="calc(1.2rem + .2vw)">Item</Flex>
  <Flex fontSize="calc(1.2rem + .2vw)">Requested</Flex>
    <Flex fontSize="calc(1.2rem + .2vw)">Ship Date </Flex>
    <Flex fontSize="calc(1.2rem + .2vw)">Ship Method</Flex>
    <Flex flex=".1" fontSize="calc(1.2rem + .2vw)"></Flex>
</Flexed>
{
item?.items?.map(ite => {
 return <Flexed className="normalrow">
  <Flex fontSize="calc(1.2rem + .2vw)">{ite.name}</Flex>
  <Flex fontSize="calc(1.2rem + .2vw)">{ite.requested}</Flex>
    <Flex fontSize="calc(1.2rem + .2vw)"><Moment style={{fontSize:"calc(1.2rem + .2vw)"}} format="MM/DD/YYYY">{new Date(ite.shipdate)}</Moment></Flex>
    <Flex fontSize="calc(1.2rem + .2vw)">{ite.shipmethod}</Flex>
    <Flex flex=".1" fontSize="calc(1.2rem + .2vw)"></Flex>
</Flexed>
})
}
</div> 
})

}

<Flexed >
<Textarea value={this.props.customerinfo?.shippingnotes?.replace(/&amp;/g, "&")} placeholder="Shipping Notes" minRows="1" rows="1"/>
</Flexed>
</div>

{/*/////////////////////////////////////////////////////////OSP///////////////////////////////////////////////////////////////////////////////////////////*/}



    </Wrapper>

  
    );
  }
}





