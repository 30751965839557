import { IoMdAdd, IoMdAddCircle } from 'react-icons/io'
import { Flex, Flexed, Input, MainWrap, PhoneNumber, Scroll, Wrap, currency } from '../../utilities/Shared'
import { LuEdit } from 'react-icons/lu'
import { MdDelete } from 'react-icons/md'
import { useNavigate } from 'react-router-dom'
import Moment from 'react-moment'
import Collapse from '../../utilities/Collapse'
import axios from 'axios'


const PoPanelpaginate = ({list,search,setSearch,setVendorinfo,setEditVendor,setDeletevendor,rowclass,navi}) => {

console.log(list)
    const nav = useNavigate()




  return (
 <Wrap>
    {/*<Flexed margin="0px 0px 20px 0px"><Flex><Input type="text" value={search} placeholder="Search" onChange={e => setSearch(e.target.value)} /></Flex>
      <Flex flex=".3" textAlign="right" ><IoMdAddCircle  style={{fontSize:"calc(1.7rem + .4vw)",color:"var(--plsGreen)"}} onClick={() => nav('plsnew')}/> </Flex>
  </Flexed>*/}

      <Flexed margin="0" style={{borderBottom:"1px solid black",padding:"4px",background:"#8398AB",color:"white",marginBottom:"5px"}}>
      <Flex>PO</Flex>
      <Flex>Vendor</Flex>
      <Flex>Job #</Flex>
<Flex flex="3">Description</Flex>
<Flex>Quantity</Flex>

<Flex>Unit Cost</Flex>
<Flex>Total</Flex>
      </Flexed>
    {list?.map(item => {
return   <>

 {item.items.length > 1 ?  <div className="hand" style={{background:"#CFCDCC",marginBottom:"8px",border:"1px solid #c0c0c0",padding:"4px 0px"}} ><Collapse  margin="0" title={<><IoMdAdd style={{paddingTop:"5px",fontSize:"calc(1rem + .4vw)",color:"green",fontWeight:"bold"}} />&nbsp;&nbsp;<span onClick={() => nav(`${navi}/${item?._id}`)} >{item.ponum}</span>   --  {item.items?.length} <span >item(s)</span   >    ---  {item.vendor?.company?.name}</>} >
      <Flexed margin="0" style={{borderTop:"1px solid black",padding:"4px",background:"#8398AB",color:"white"}}>
      <Flex></Flex>
    
      <Flex>Job #</Flex>
<Flex flex="3">Description</Flex>
<Flex>Quantity</Flex>

<Flex>Unit Cost</Flex>
<Flex>Total</Flex>
      </Flexed>
    
  {item.items.map(ite => {
      return   <Flexed  margin="0" style={{borderTop:"1px solid black",padding:"4px",background:"white",color:"var(--plsBlue)",}}> 
  <Flex></Flex>

  <Flex> <a href={`https://highgroundsoftware.com/dash/dashboard/?des=800&job=${ite?.jobnum}`}>{ite?.jobnum}</a></Flex>
<Flex flex="3">{ite?.name}</Flex>

<Flex>{ite?.quantity}</Flex>
<Flex>{parseInt(ite?.unitprice)}&nbsp;{ite?.orderby}</Flex>
<Flex>{ite?.extprice}</Flex>
      </Flexed>
     /* <Flexed style={{border:"1px solid red"}}><Flex >
                  <Flex style={{border:"1px solid red"}}>{ite.jobnum}</Flex>
                  <Flex style={{border:"1px solid red"}}>{ite.quantiy}</Flex>
                  <Flex style={{border:"1px solid red"}} flex="3">{ite.itemname}</Flex>
                  <Flex style={{border:"1px solid red"}}>{ite.unitprice}</Flex>
                  <Flex style={{border:"1px solid red"}}>{ite.total}</Flex>
                  </Flex>
                  </Flexed> */
     }) }
</Collapse> </div>  :
 <> 
 <Flexed style={{padding:"4px",marginBottom:"8px"}} bgColor="#CFCDCC" className="hand">
 <Flex onClick={() => nav(`${navi}/${item?._id}`)}>{item?.ponum}</Flex>
            <Flex>{item.vendor?.company?.name}</Flex>
            <Flex><a href={`https://highgroundsoftware.com/dash/dashboard/?des=800&job=${item.items[0]?.jobnum}`}>{item.items[0]?.jobnum > 0 ? item.items[0]?.jobnum : ""}</a></Flex>

                  <Flex flex="3">{item.items[0]?.name}</Flex>
                  <Flex>{item.items[0]?.quantity}</Flex>
                  <Flex>{currency(item.items[0]?.unitprice)}&nbsp;{item.items[0]?.orderby}</Flex>
                  <Flex>{currency(item.items[0]?.extprice)}</Flex>
      </Flexed>
      </>
}





</>
}).reverse()

}

 </Wrap>
  )
}

export default PoPanelpaginate