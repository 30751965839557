import React, { useEffect, useState } from "react";
import axios from "axios";
import GreenBar from '../../Assets/headerbargreen.png'
import greenflag from '../../Assets/flaggreen.png'
import Spin from '../../components/Spinner/Spin'

import Pagination from "../../components/Pageinate/Paginate";
import PoPanel from "./PoPanel";
import { Flex, Flexed, Img, MainWrap, Wrap } from "../../utilities/Shared";
import PoPanelpaginate from "./PopanelPaginate";

const Closedpurchase = () => {
    const [coinsData, setCoinsData] = useState([]);
    const [currentPage, setCurrentPage] = useState(1);
    const [postsPerPage, setPostsPerPage] = useState(500);
    const [search, setSearch] = useState();
    const [spin, setSpin] = useState(false)
    useEffect(() => {

        const getit = async() => {
            setSpin(true)
             await axios.get("https://highgroundapi.herokuapp.com/po/closed")
             //await axios.get("http://localhost:5003/po/closed")
    .then(res => {setCoinsData(res.data.po);  setSpin(false)})
    .catch()
             
        }
        getit()
    }, []);


    console.log(coinsData)
    const lastPostIndex = currentPage * postsPerPage;
    const firstPostIndex = lastPostIndex - postsPerPage;
    const currentPosts = coinsData?.slice(firstPostIndex, lastPostIndex);

    return (
        <MainWrap>
            {spin && <Spin asOverlay />} 
      <Flexed><Flex style={{whiteSpace:"nowrap"}} margin="30px 0px 20px 0px" fontSize="calc(1.2rem + .4vw)" textAlign="left" padding="20px 0px" fontWeight="bold" color="#7ABB43"
         >
         <Flexed style= {{position:"relative",top:"-10px"}}>
          <Flex flex="0" fontSize="calc(1.2rem + .4vw)">Closed Purchase Orders</Flex>
          <Flex> <Img src={GreenBar} style={{width:"100%",height:"24px",}}/></Flex>
          </Flexed> 
          <Flexed><Flex fontSize="calc(1.2rem + .4vw)" style={{opacity:"1",background:"var(--plsBlue)",height:"24px",marginTop:"-10px",position:"relative"}}><Img src={greenflag} style={{width:"22px",zIndex:"20"}}/></Flex></Flexed>
          
         </Flex></Flexed>
            <PoPanelpaginate  list={currentPosts} navi="/pos/ponew" search={search} setSearch={setSearch}/>
            <Pagination
                totalPosts={coinsData?.length}
                postsPerPage={postsPerPage}
                setCurrentPage={setCurrentPage}
                currentPage={currentPage}
            />
                   </MainWrap>
  
    );
};

export default Closedpurchase;