import React, { useContext, useEffect, useRef, useState } from 'react'
import { Button, Flex, Flexed, MainWrap, Scroll, TBody, THead, Table, Td, Th, Tr, Wrap } from '../../../utilities/Shared'
import axios from 'axios'
import { sortdate, sortit, sortnum } from '../../../utilities/Sort'
import Moment from 'react-moment'
import { FaCopy } from "react-icons/fa";
import { colorchange, getemployeebynamefromempid } from '../../../utilities/Convertions'

import { toast } from 'react-toastify';
import UserContext from '../../../Context/UserContext'

const Colormax = () => {

const [activejobs, setActivejobs] = useState()
const [jobs, setJobs] = useState()
const [imp, setImp] = useState([])
const [newer, setNewer] = useState([])
const [ops, setOps] = useState([])
const [digops, setDigops] = useState([])
const [order, setOrder] = useState("ASC")
const [reset, setReset] = useState(false)
const [jobids, setJobids] = useState([])

const { employees }  = useContext(UserContext)

const textAreaRef = useRef(null);

const config = {
    headers: {
      'Content-Type': 'application/json',
      'Access-Control-Allow-Origin':'*' 
    }
  };
    useEffect(() => {
        const config = {
            headers: {
              'Content-Type': 'application/json',
              'Access-Control-Allow-Origin':'*' 
            }
          };
          let r = {
            job:"123456"
          }
          const getactive = async() => { 
            await axios.post(`https://highgroundsoftware.com/dash/dashboard/php/getactivejobs.php`,r,config)
      .then(res => {
        let u = []
        res.data.map(item => {
u.push(item.job)
        })
        let ids = u
        setActivejobs(res.data)
        setJobids(u)
      })
      .catch(error => { console.log(error)})
          }

     
          
          getactive()     
    },[reset])


useEffect(() => {
  getimp(jobids)
},[jobids])

    const getimp = async(ids) => { 
      let r ={ids:ids}
      let rr = []
    
      await axios.post(`https://highgroundsoftware.com/dash/dashboard/php/getcolormax.php`,r,config)
.then(res => {
  let io =[]
  let newids = []
  res.data.map(item => {
   
if( parseInt(item.numforms) > 1){
for(let i=1;i <= parseInt(item?.numforms);i++){ 

io = {
...item,
partofid:`${item.id}${i}`,
partof:item.id,
name:`${i}) ${item.component}`,


} 
rr.push(io)     

}
}else{

io = {
...item,
partofid:0,
partof:item.id,
name:item.component,

} 
rr.push(io)
}
newids.push(item.job)
  })
  
  
  getops(newids,rr); 
}) 

.catch(error => { console.log(error)})
    }


    const getops= async(ids,rr) => { 
  
      let f ={ids:ids}
      let op = []
      await axios.post(`https://highgroundsoftware.com/dash/dashboard/php/getoffpress.php`,f,config)
  .then(res => {
   
    
rr.map((item,index) => {

let u = res.data.filter(filt => parseInt(filt.partof) === parseInt(item.partof) && parseInt(filt.partofid) === parseInt(item.partofid))

let exists = u.find(el => el.name === "Off Colormax" || el.name === "Off Digital");
if(exists){

}else{

let uu = activejobs?.filter(filtt => parseInt(filtt.job) === parseInt(item.job))

let hh = {
...item,
stat:u[0]?.name || "Incoming",
impp:activejobs?.filter(filtt => parseInt(filtt.job) === parseInt(item.job)),
newquantity:getquantity(item),
customer:uu[0]?.customer,
salesrep:getemployeebynamefromempid(uu[0]?.salesrep,employees),
dateentered:uu[0]?.dateentered,
datedue:uu[0]?.duedate
}
op.push(hh)
}
setJobs(op);
})

   })
  .catch(error => { console.log(error)})
  
    }
    
  
  const getquantity = (item) => {
let a = parseInt(item.needed) + parseInt(item.mroverride)  + parseInt(item.additionalmr)
if(item.mroverride == 0){
a +=  parseInt(item.mr)
}
let b = a   / parseInt(item.numforms)
return b
  }


  const CopyAllToClipboard = () => {
    var tb = document.getElementById('gl40');
    tb.getElementsByTagName("thead")[0].style.display = "none";
    var range = document.createRange();
                 range.selectNode(document.getElementById('gl40'));
                 window.getSelection().removeAllRanges();
                 window.getSelection().addRange(range);
                 document.execCommand("copy");
                 toast.success(`Copied Job To Clipboard`)
                 tb.getElementsByTagName("thead")[0].style.display = "block";
         }

const CopyToClipboard = (containerid) => {
    var tb = document.getElementById(containerid);
 tb.getElementsByTagName("td")[0].style.display = "none";
  var range = document.createRange();
               range.selectNode(document.getElementById(containerid));
               window.getSelection().removeAllRanges();
               window.getSelection().addRange(range);
              
               document.execCommand("copy");
               tb.getElementsByTagName("td")[0].style.display = "none";
               toast.success(`Copied Job To Clipboard`)
               tb.getElementsByTagName("td")[0].style.display = "block";
         //showTH(containerid);
         }	

        
         

  return (
    <Wrap padding="0px"> 
        <Flexed  margin="5px 0px 0px 0px" bgColor="#79BB43" color="white"  padding="5px"><Flex fontSize="calc(1.1rem + .4vw)">Colormax Active Jobs - {jobs?.length}</Flex>
        <Flex textAlign="right"><Button bgcolor="orange" onClick={e => CopyAllToClipboard()}>Copy Table</Button></Flex>
        </Flexed>
        <Scroll height="70vh">  
<Table id="gl40">
<THead>
<Tr>

<Th flex=".8" onClick={e => sortnum('job',order,jobs,setJobs,setOrder)} className="hand">Job #</Th>
<Th flex="3" className="hand" onClick={e => sortit('customer',order,jobs,setJobs,setOrder)}>Customer</Th>
<Th flex="1.5" className="hand" onClick={e => sortit('customer',order,jobs,setJobs,setOrder)} >Component</Th>
<Th  onClick={e => sortnum('parentsheetl',order,jobs,setJobs,setOrder)}  className="hand">Sheet Size</Th>
<Th  className="banned">&nbsp;</Th>
<Th onClick={e => sortnum('newquantity',order,jobs,setJobs,setOrder)}  className="hand">Quantity</Th>
<Th flex="1.5" className="banned">Run Type</Th>
<Th flex="3" className="banned">Description</Th>
<Th onClick={e => sortit('salesrep',order,jobs,setJobs,setOrder)}  className="hand">Sales Rep</Th>
<Th flex="1.5" onClick={e => sortdate('dateentered',order,jobs,setJobs,setOrder)} className="hand">Enter Date</Th>
<Th flex="1.5" onClick={e => sortdate('dateentered',order,jobs,setJobs,setOrder)}  className="hand">Due Date</Th>
<Th flex="1.5" onClick={e => sortit('stat',order,jobs,setJobs,setOrder)}  className="hand">Status</Th>

</Tr></THead>
 <TBody  hbgcolor="#79BB43">
 {jobs?.map((job,index) => {
 return <Tr id={`gl4o${index}`} className="hand">

<Td flex=".8"><a className="hand" href={`/jobticket/${job.job}`} >{job.job}</a></Td>
<Td flex="3">{job?.impp[0]?.customer}</Td>
<Td flex="1.5">{job.name}</Td>
<Td >{job.sheetl}X{job.sheetw}</Td>
<Td > </Td>
<Td >{Math.ceil(job.newquantity)}</Td>
<Td flex="1.5">{job.method}</Td>
<Td flex="3">{job?.impp[0]?.jobname}</Td>
<Td >{getemployeebynamefromempid(job?.impp[0]?.salesrep,employees)}</Td>
<Td flex="1.5"><Moment format="MM/DD/YYYY">{job.impp[0]?.dateentered}</Moment></Td>
<Td flex="1.5"><Moment format="MM/DD/YYYY">{job.impp[0]?.duedate}</Moment></Td>
<Td flex="1.5" bgColor={colorchange(job.stat)}>
  <span style={{padding:"3px",display:"flex",alignItems:"center"}}>{job.stat}</span></Td>
</Tr>
 })
}
<Tr>
<Td flex=".6"></Td>
<Td flex=".8"></Td>
<Td flex="3"></Td>
<Td flex="1.5"></Td>
<Td ></Td>
<Td > </Td>
<Td ></Td>
<Td flex="1.5"></Td>
<Td flex="3"></Td>
<Td ></Td>
<Td flex="1.5"></Td>
<Td flex="1.5"></Td>
<Td flex="1.5"></Td>
</Tr>
 </TBody>
 </Table>
</Scroll>
   
    </Wrap>
  )
}

export default Colormax

