import { IoMdAdd, IoMdAddCircle } from 'react-icons/io'
import { Flex, Flexed, Input, MainWrap, PhoneNumber, Scroll, Wrap, currency } from '../../utilities/Shared'
import { LuEdit } from 'react-icons/lu'
import { MdDelete } from 'react-icons/md'
import { useNavigate } from 'react-router-dom'
import Moment from 'react-moment'
import Collapse from '../../utilities/Collapse'
import axios from 'axios'



const CfpPoPanel = ({list,search,setSearch,setVendorinfo,setEditVendor,setDeletevendor,rowclass,navi}) => {

console.log(list)
    const nav = useNavigate()

const pototal = (item) => {
      let k = 0
item.items?.map(item => {
k += item.extprice
})
return k
}

const forjobslist = (item) => {
let k = []
item.items?.map(item => {
      if(item.forjobs?.length > 0){
            item.forjobs?.map(ite => {
                  k.push(ite.job)
                 })
      }else{
            k.push(item.jobnum)
      }

      })
      return k
}
console.log(list)
  return (
 <Wrap>
    <Flexed margin="0px 0px 20px 0px"><Flex><Input type="text" value={search} placeholder="Search" onChange={e => setSearch(e.target.value)} /></Flex>
      <Flex flex=".3" textAlign="right" ><IoMdAddCircle  style={{fontSize:"calc(1.7rem + .4vw)",color:"var(--plsGreen)"}} onClick={() => nav('cfpnewpo')}/> </Flex>
      </Flexed>

      <Flexed margin="0" style={{borderBottom:"1px solid black",padding:"4px",background:"#8398AB",color:"white",marginBottom:"5px"}}>
      <Flex flex=".1"></Flex>
      <Flex>PO</Flex>
      <Flex flex="2">Vendor</Flex>
      <Flex>Ordered By</Flex>
      <Flex flex="1.5">Job(s)</Flex>
     
<Flex>Item(s)</Flex>


<Flex>Ordered</Flex>
<Flex>Due</Flex>
<Flex>Cost</Flex>
      </Flexed>
    {list?.filter(ite => {return search?.toLowerCase() === '' ? ite : ite.ponum.toString().includes(search)}).reverse().map((item) => {
return <>
<div className="hand" style={{background:"#CFCDCC",marginBottom:"8px",border:"1px solid #c0c0c0",padding:"4px 0px"}} ><Collapse  margin="0" title={<Flexed alignItems="center">
      <Flex flex=".1"><IoMdAdd style={{paddingTop:"5px",fontSize:"calc(1.3rem + .4vw)",color:"green",fontWeight:"bold"}} /></Flex>
      <Flex onClick={e => nav(`/pos/cfppo/${item._id}`)}>{item.poid}</Flex>
      <Flex flex="2">{item.vendor?.name}</Flex>
      <Flex >{item.createdBy ? item.createdBy?.firstName+" "+item.createdBy?.lastName?.substr(0,1) : "Robert H"}</Flex>
      <Flex flex="1.5">{/*forjobslist(item) ? forjobslist(item)?.map(item => {return item+"  "}) : ""*/}</Flex>
      <Flex>{item.items.length}</Flex>
    
      <Flex><Moment format="MM/DD/YYYY">{item.createdAt}</Moment></Flex>
      <Flex><Moment format="MM/DD/YYYY">{item.duedate}</Moment></Flex>
      <Flex>{currency(pototal(item))}</Flex>
</Flexed>} >
<Flexed margin="0" style={{borderTop:"1px solid black",padding:"4px",background:"#8398AB",color:"white"}}>
            <Flex >Job(s)</Flex>
<Flex flex="3">Description</Flex>
<Flex>Quantity</Flex>
<Flex>Unit Cost</Flex>
<Flex>Total</Flex>
      </Flexed>
{item.items.map((item,index) => {
return <>
 
      <Flexed margin="0" style={{borderTop:"1px solid black",padding:"4px",background:"white",color:"black"}}>
      {item.forjobs ? item.forjobs.map(ite => {return <Flex ><a href={`https://highgroundsoftware.com/dash/dashboard/?des=800&job=${ite?.job}`}>{ite.job} - {ite.quantity}<br /></a></Flex>}): <Flex ><a href={`https://highgroundsoftware.com/dash/dashboard/?des=800&job=${item?.jobnum}`}>{item.jobnum}</a></Flex>}
<Flex flex="3">{item.name}</Flex>
<Flex>{item.quantity+" "+item.orderby}</Flex>
<Flex>{item.unitprice+" "+item.priceby}</Flex>
<Flex>{currency(item.extprice)}</Flex>
      </Flexed>
      </>
})




}
</Collapse>
</div>

{/*
 {item.items.length > 1 ?  <div className="hand" style={{background:"#CFCDCC",marginBottom:"8px",border:"1px solid #c0c0c0",padding:"4px 0px"}} ><Collapse  margin="0" title={<><IoMdAdd style={{paddingTop:"5px",fontSize:"calc(1rem + .4vw)",color:"green",fontWeight:"bold"}} />&nbsp;&nbsp;<span onClick={() => nav(`/pos/ponew/${item?._id}`)} >{item.ponum}</span>   --  {item.items?.length} <span >item(s)</span   >    ---  {item.vendor?.company?.name}<span style={{float:"right",marginRight:"20px"}}><Moment format="MM/DD/YYYY">{item.dueDate}</Moment></span> <span style={{float:"right",marginRight:"20px"}}><Moment format="MM/DD/YYYY">{item.createdAt}</Moment></span></>} >
      <Flexed margin="0" style={{borderTop:"1px solid black",padding:"4px",background:"#8398AB",color:"white"}}>
      <Flex></Flex>
    
      <Flex>Job #</Flex>
<Flex flex="3">Description</Flex>
<Flex>Quantity</Flex>

<Flex>Unit Cost</Flex>
<Flex>Total</Flex>

      </Flexed>
    
  {item.items.map((ite,index) => {
  return  ite.forjobs ?
ite.forjobs.map((item,index) => {
return <Flexed  margin="0" style={{borderTop:"1px solid black",padding:"4px",background:"white",color:"var(--plsBlue)",}}> 
      <Flex></Flex>
<Flex>
        <a href={`https://highgroundsoftware.com/dash/dashboard/?des=800&job=${item?.job}`} >{item?.job}</a> <br />
        </Flex>
      <Flex flex="3">{ite?.name}</Flex>
<Flex>{item?.quantity}</Flex>
<Flex>{parseInt(ite?.unitprice)}&nbsp;{ite?.priceby}</Flex>
<Flex>{currency(ite?.extprice)}</Flex>
</Flexed>})
:
      <>
   <Flexed style={{border:"1px solid red"}}><Flex >
                  <Flex style={{border:"1px solid red"}}>{ite.jobnum}</Flex>
                  <Flex style={{border:"1px solid red"}}>{ite.quantiy}</Flex>
                  <Flex style={{border:"1px solid red"}} flex="3">{ite.itemname}</Flex>
                  <Flex style={{border:"1px solid red"}}>{ite.unitprice}</Flex>
                  <Flex style={{border:"1px solid red"}}>{ite.total}</Flex>
                  </Flex>
                  </Flexed> 
                  </>
    })}

     
</Collapse> </div>  :
 <> 
 <Flexed style={{padding:"4px",marginBottom:"8px"}} bgColor="#CFCDCC" className="hand">
 <Flex onClick={() => nav(`/pos/ponew/${item?._id}`)}>{item?.ponum}</Flex>
            <Flex>{item.vendor?.company?.name}</Flex>
            <Flex>
            {item?.items[0]?.forjobs ?  <a href={`https://highgroundsoftware.com/dash/dashboard/?des=800&job=${item?.items[0]?.forjobs[0]?.job}`} >{item?.items[0]?.forjobs[0]?.job}</a> : <a href={`https://highgroundsoftware.com/dash/dashboard/?des=800&job=${item?.items[0]?.jobnum}`}>{item?.items[0]?.jobnum}</a>}
      
                  
                  
                  </Flex>

                  <Flex flex="3">{item.items[0]?.name}</Flex>
                  <Flex>{item.items[0]?.quantity}</Flex>
                  <Flex>{currency(item.items[0]?.unitprice)}&nbsp;{item.items[0]?.orderby}</Flex>
                  <Flex>{currency(item.items[0]?.extprice)}</Flex>
                  <Flex><Moment format="MM/DD/YYYY">{item?.createdAt}</Moment></Flex>
                  <Flex><Moment format="MM/DD/YYYY">{item?.dueDate}</Moment></Flex>
      </Flexed>
      </>
}

*/}



</>
})

}

 </Wrap>
  )
}

export default CfpPoPanel