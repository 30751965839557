import { Tab, Tabs, TabList, TabPanel } from 'react-tabs';
import 'react-tabs/style/react-tabs.css';
import { Flex, Flexed, Input, MainWrap, Wrap, currency,PhoneNumber, Img } from '../../utilities/Shared';
import { useEffect, useState } from 'react';
import axios from 'axios';
import Modal from '../../modals/Modal';
import NewVendor from './vendor/NewVendor';
import EditVendor from './vendor/EditVendor';
import DeleteConfirm from '../../utilities/DeleteConfirm';
import { useNavigate } from 'react-router-dom';
import GreenBar from '../../Assets/headerbargreen.png'
import greenflag from '../../Assets/flaggreen.png'
import PoPanel from './PoPanel';
import Spin from '../../components/Spinner/Spin'
import CfpPoPanel from './CfpPoPanel';



const Pos = () => {

const [search, setSearch] = useState("")
const [polist, setPolist] = useState([])
const [cfppolist, setCfpolist] = useState([])
const [closedpolist, setClosedpolist] = useState([])
const [newVendor, setNewVendor] = useState(false)
const [reset, setReset] = useState(false)
const [editVendor, setEditVendor] = useState(false)
const [deletevendor, setDeletevendor] = useState(false)
const [vendorinfo, setVendorinfo] = useState({})
const [company, setCompany] = useState("PLS")
const [spin, setSpin] = useState(false)
const nav = useNavigate()

useEffect(() => {
  setSpin(true)
  const getpolist = async() => { 
 await axios.post('https://highgroundapi.herokuapp.com/po/search')
 //await axios.post('http://localhost:5003/po/search')
      .then(res => {console.log(res.data);setPolist(res.data.po)})
      .catch(err => {console.log(err);})
  }
  const getcfppolist = async() => { 
    await axios.get(' https://cfpapi-6ab062006fa0.herokuapp.com/pos/getall')
    .then(res => {setCfpolist(res.data)})
    .catch((err) => console.log(err))
     }
     getcfppolist()
  getpolist() 
  setSpin(false)
},[reset])

console.log(spin)
const DeleteVendor = async() => {

  let newinfo = {"id":vendorinfo._id}
//await axios.post('https://highgroundapi.herokuapp.com/paper/delete/one',)
await axios.post('https://highgroundapi.herokuapp.com/vendor/remove',newinfo)
.then(res => {
  const config = {
    headers: {
      'Content-Type': 'application/json',
      'Access-Control-Allow-Origin':'*' 
    }
  };
  const tr = {"id":vendorinfo.company.company_id}
  
//axios.post(`https://highgroundsoftware.com/dash/dashboard/php/removepaper.php`,tr,config);
axios.post(`https://highgroundsoftware.com/dash/dashboard/php/removevendor.php`,tr,config)
.then(res => {setReset(!reset);setDeletevendor(false)})
.catch(err => console.log(err))
console.log(tr)
})
.catch(err => console.log(err))
}
console.log(polist)
console.log(cfppolist)

  return (
    <MainWrap>


<Modal show={newVendor} header={`Add New Vendor`} onCancel={() => {setNewVendor(false)}} ><NewVendor onCancel={() => {setNewVendor(false)}}  reset={reset} setReset={setReset}/></Modal>
    <Modal show={editVendor} header={`Edit Vendor`} onCancel={() => {setEditVendor(false)}} ><EditVendor onCancel={() => {setEditVendor(false)}}  reset={reset} setReset={setReset} vendorinfo={vendorinfo}/></Modal>
    <Modal show={deletevendor} header={`Confirm you are deleting vendor -- ${vendorinfo?.company?.name}`} onCancel={() => {setDeletevendor(false)}} ><DeleteConfirm editinfo={vendorinfo} reset={reset} setReset={setReset} handleChange={DeleteVendor} onCancel={() => {setDeletevendor(false)}} /></Modal>
   
<div>
{spin && <Spin asOverlay />} 
<Flexed><Flex style={{whiteSpace:"nowrap"}} margin="30px 0px 20px 0px" fontSize="calc(1.2rem + .4vw)" textAlign="left" padding="20px 0px" fontWeight="bold" color="#7ABB43"
         >
         <Flexed style= {{position:"relative",top:"-10px"}}>
          <Flex flex="0" fontSize="calc(1.2rem + .4vw)">Purchase Orders</Flex>
          <Flex> <Img src={GreenBar} style={{width:"100%",height:"24px",}}/></Flex>
          </Flexed> 
          <Flexed><Flex fontSize="calc(1.2rem + .4vw)" style={{opacity:"1",background:"var(--plsBlue)",height:"24px",marginTop:"-10px",position:"relative"}}><Img src={greenflag} style={{width:"22px",zIndex:"20"}}/></Flex></Flexed>
          
         </Flex></Flexed>


 <Tabs defaultIndex={localStorage.getItem('default01')}>
    <TabList style={{marginBottom:"40px"}} >
    <Tab onClick={e => {setCompany("PLS");localStorage.setItem('default01',0);localStorage.setItem('company','PLS')}}>PLS Print - {polist?.length || 0} Items</Tab>
    <Tab onClick={e => nav('closedpo')} >Closed PLS PO's</Tab>
    <Tab onClick={e => {setCompany("CFP");localStorage.setItem('default01',2);localStorage.setItem('company','CFP')}}>CFP - {cfppolist?.length || 0} Items</Tab>
    <Tab onClick={e => nav('closedpo')} >Closed CFP PO's</Tab>

  </TabList>

  <TabPanel >

  <PoPanel setSearch={setSearch} search={search} list={polist} navi="po"/>

  </TabPanel>
{/*/////////////////////////////////////////////////////CFP*/}
  <TabPanel>
  <PoPanel setSearch={setSearch} search={search}/>
  </TabPanel>
{/*/////////////////////////////////////////////////////MPS*/}
  <TabPanel>
  <CfpPoPanel setSearch={setSearch} search={search} list={cfppolist}/>

  </TabPanel>
  </Tabs>
  </div>
  </MainWrap>
  )
}

export default Pos