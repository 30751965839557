import React, { useContext, useEffect, useState } from 'react'
import { Flex, Flexed, Scroll, currency } from '../../utilities/Shared'
import { sortdate, sortit, sortnum } from '../../utilities/Sort'
import axios from 'axios'
import { NavLink, useNavigate } from 'react-router-dom'
import Cfpsales from './Cfpsales'



const Salesdetails = ({salesreps,setSalesreps,jobstoday}) => {
    const[jobs, setJobs] = useState([])
    const[order, setOrder] = useState("ASC")
    const[thisweek, setThisweek] = useState(0)
    const[newlist, setNewlist] = useState([])
    const[cfpsales, setCFPsales] = useState([])
    
    const workingDate = new Date()
    const nav = useNavigate()

    useEffect(() => {
        const config = {
            headers: {
              'Content-Type': 'application/json',
              'Access-Control-Allow-Origin':'*' 
            }
          };
  
        const getjobs = async() => {
            await axios.post('https://highgroundsoftware.com/dash/dashboard/php/getallsalesjobs.php',config)
            .then(res => {setJobs(res.data)})
            .catch(error => { console.log(error)})
        }

        const getAllSales = async() => { 
         await axios.get(`https://cfpapi-6ab062006fa0.herokuapp.com/jobs/get/all/sales`)
         .then(res => {console.log(res.data);setCFPsales(res.data) })
         .catch(err => console.log(err))
      }
      getAllSales()
        getjobs()
    },[])


    console.log(cfpsales)

    useEffect(() => {
      
      let y
      let t = []
      const you = () => {
        salesreps.map(rep => {
           y = {'name':rep.firstName+' '+rep.lastName.substr(0,1),'today':today(rep),'thisweek':thiswee(rep),'lastweek':lastweek(rep),'MTD':monthtodate(rep),'QTD':quartertodate(rep),'YTD':yeartodate(rep),'id':rep.empId}
        
    t.push(y)

        })
        setNewlist(t)
      }
    you()
      
    },[jobs])




    const today = (rep) => {
        let ee = 0
        let r = jobs.filter(job => job.salesrep === rep.empId && new Date(job.dateentered).toLocaleDateString() === workingDate.toLocaleDateString()).map(item => {   
ee += parseFloat(item.currentprice)
        })
       // console.log(r)
      return(ee)
    }

    const todaytotals = () => {
      let ee = 0
      jobstoday?.map(job =>  ee += parseFloat(job.currentprice))
      ee += parseFloat(cfpsales?.today)
     return(ee)
  }

    const thiswee = (rep) => {
     let d = new Date();
      let day = d.getDay(),


diff = d.getDate() - day ; // adjust when day is sunday
 let start =  new Date(d.setDate(diff))

 let end =  d.setDate(d.getDate() + 4)
                let ee = 0
                let r = jobs.filter(job => job.salesrep === rep.empId && new Date(job.dateentered) > new Date(start)).map(item => {     
        ee += parseFloat(item.currentprice)
                })
               return(ee)   

  
            }

            const thisweektotals = (rep) => {
              let d = new Date();
              let day = d.getDay(),
        diff = d.getDate() - day + (day == 0 ? -6 : 1); // adjust when day is sunday
      
         d.setHours(0,0,0,0);
         let start =  new Date(d.setDate(diff))
                       let ee = 0
                       let r = jobs.filter(job => new Date(job.dateentered) > new Date(start)).map(item => {     
               ee += parseFloat(item.currentprice)
                       })
                       ee += parseFloat(cfpsales?.week)
                      return(ee)   
                   }


            const lastweek = (rep) => {
                let a =  new Date().getDay()
                let d = new Date()
                d.setHours(0,0,0,0);
          let start =  d.setDate(d.getDate() - a -7)
         
          let b = new Date(start)
          b.setHours(0,0,0,0);

      let end = b.setDate(b.getDate() + 6)

              let ee = 0      
         let r = jobs.filter(job => job.salesrep === rep.empId && new Date(job.dateentered) >= start && new Date(job.dateentered) <= end).map(item => {     
        ee += parseFloat(item.currentprice)
            })

           
               return(ee) 
                     }


                     const lastweektotals = () => {
                        let a =  new Date().getDay()
                        let d = new Date()
                        d.setHours(0,0,0,0);
                  let start =  d.setDate(d.getDate() - a -7)
                 
                  let b = new Date(start)
                  b.setHours(0,0,0,0);
        
              let end = b.setDate(b.getDate() + 6)
        
                      let ee = 0      
               let r = jobs.filter(job => new Date(job.dateentered) >= start && new Date(job.dateentered) <= end).map(item => {     
              ee += parseFloat(item.currentprice)
                  })
                  ee += parseFloat(cfpsales?.lastweek)
                     return(ee) 
                           }
           


const yeartodate = (rep) => {

  let r
     let m = workingDate.getMonth() + 1;
    let y = workingDate.getFullYear();
  
let ee= 0
 jobs.filter(job => job.salesrep === rep.empId && new Date(job.dateentered).getFullYear() === y ).map(item => {     
 ee += parseFloat(item.currentprice)
              })
return(ee)
}

const yeartodatetotals = () => {

  let r
     let m = workingDate.getMonth() + 1;
    let y = workingDate.getFullYear();
  
let ee= 0
 jobs.filter(job => new Date(job.dateentered).getFullYear() === y ).map(item => {     
 ee += parseFloat(item.currentprice)
              })
              ee += parseFloat(cfpsales?.year)
return(ee)
}

const monthtodate = (rep) => {
 
  let r
     let m = workingDate.getMonth() + 1;
    let y = workingDate.getFullYear();
    let start = `${m}/01/${y}`
    let end = `${m}/31/${y}`

let ee= 0
jobs.filter(job => job.salesrep === rep.empId && new Date(job.dateentered) >= new Date(start) && new Date(job.dateentered) <= new Date(end)).map(item => {     
  ee += parseFloat(item.currentprice)
          })
return(ee)
}

const monthtodatetotal = () => {
  let r
     let m = workingDate.getMonth() + 1;
    let y = workingDate.getFullYear();
    let start = `${m}/01/${y}`
    let end = `${m}/31/${y}`

let ee= 0
jobs.filter(job => new Date(job.dateentered) >= new Date(start) && new Date(job.dateentered) <= new Date(end)).map(item => {     
  ee += parseFloat(item.currentprice)
          })
          ee += parseFloat(cfpsales?.month)
return(ee)
}

const getquarter= (m) => {

  let start
  let end
  let  y = workingDate.getFullYear();

  switch(m){
		case 1:
      start = new Date(`01/01/${y}`)
      end = new Date(`03/31/${y}`)
		break;
		case 2:
      start = new Date(`01/01/${y}`)
      end = new Date(`03/31/${y}`)
		break;
		case 3:
      start = new Date(`01/01/${y}`)
      end = new Date(`03/31/${y}`)
		break;
		case 4:
      start = new Date(`04/01/${y}`)
      end = new Date(`06/30/${y}`)
		break;
		case 5:
      start = new Date(`04/01/${y}`)
      end = new Date(`06/30/${y}`)
		break;
		case 6:
      start = new Date(`04/01/${y}`)
      end = new Date(`06/30/${y}`)
		break;
		case 7:
      start = new Date(`07/01/${y}`)
      end = new Date(`09/30/${y}`)
		break;
		case 8:
		  start = new Date(`07/01/${y}`)
      end = new Date(`09/30/${y}`)
		break;
		case 9:
      start = new Date(`07/01/${y}`)
      end = new Date(`09/30/${y}`)
		break;
		case 10:
      start = new Date(`10/01/${y}`)
      end = new Date(`12/31/${y}`)
   
		break;
		case 11:
      start = new Date(`10/01/${y}`)
      end = new Date(`12/31/${y}`)
		break;
		case 12:
      start = new Date(`10/01/${y}`)
      end = new Date(`12/31/${y}`)
		break;
	}
  return {start,end}
}


const quartertodate = (rep) => {
     let m = workingDate.getMonth() + 1;
  
    let get = getquarter(m)
   
let start = get.start
let end = get.end

let ee= 0
jobs?.filter(job => job.salesrep === rep.empId && new Date(job.dateentered) >= new Date(start) && new Date(job.dateentered) <= new Date(end)).map(item => {     
  ee += parseFloat(item.currentprice)
       })
return(ee)
}

const quartertodatetotal = () => {
  let m = workingDate.getMonth() + 1;
 let get = getquarter(m)
let start = get.start
let end = get.end

let ee= 0
jobs?.filter(job => new Date(job.dateentered) >= new Date(start) && new Date(job.dateentered) <= new Date(end)).map(item => {     
ee += parseFloat(item.currentprice)
    })
    ee += parseFloat(cfpsales?.quarter)
return(ee)
}





 

                     






  return (
    <>
    <Flexed  margin="30px 0px 0px 0px" bgColor="#8398AB" color="white"  padding="5px 0px"><Flex fontSize="calc(1.1rem + .4vw)">Sales Report</Flex></Flexed>
    <Flexed style={{borderBottom:"1px solid black",padding:"4px",background:"white"}} className="hand">
      <Flex fontSize="14px" flex="1.5" onClick={e => sortit('name',order,newlist,setNewlist,setOrder)}>Sales Rep</Flex>
      <Flex fontSize="14px" onClick={e => sortnum('today',order,newlist,setNewlist,setOrder)}>Today</Flex>
      <Flex fontSize="14px" onClick={e => sortnum('thisweek',order,newlist,setNewlist,setOrder)}>This Week</Flex>
      <Flex fontSize="14px" onClick={e => sortnum('lastweek',order,newlist,setNewlist,setOrder)}>Last Week</Flex>
      <Flex fontSize="14px" onClick={e => sortnum('MTD',order,newlist,setNewlist,setOrder)}>MTD</Flex>
      <Flex fontSize="14px" onClick={e => sortnum('QTD',order,newlist,setNewlist,setOrder)}>QTD</Flex>
      <Flex fontSize="14px" onClick={e => sortnum('YTD',order,newlist,setNewlist,setOrder)}>YTD</Flex>
    </Flexed>
    {newlist.length > 1 ?
    <Scroll  height="auto" border="1px solid #303030">
    {
     newlist?.map(item => {
        return <Flexed className="normalrow hand" padding="3px" >
          <Flex fontSize="12px"  flex="1.5"><NavLink to={`https://highgroundsoftware.com/dash/dashboard/?des=10301&r=${item.id}&s=today`}>{item.name}</NavLink></Flex>
          <Flex fontSize="12px"><NavLink to={`https://highgroundsoftware.com/dash/dashboard/?des=10301&r=${item.id}&s=today`}>${currency(item.today)}</NavLink></Flex>
          <Flex fontSize="12px" ><NavLink to={`https://highgroundsoftware.com/dash/dashboard/?des=10301&r=${item.id}&s=tw`}>${currency(item.thisweek)}</NavLink></Flex>
          <Flex fontSize="12px"> <NavLink to={`https://highgroundsoftware.com/dash/dashboard/?des=10301&r=${item.id}&s=lw`}>${currency(item.lastweek)}</NavLink></Flex>
          <Flex fontSize="12px"><NavLink to={`https://highgroundsoftware.com/dash/dashboard/?des=10301&r=${item.id}&s=tm`}>${currency(item.MTD)}</NavLink></Flex>
          <Flex fontSize="12px"><NavLink to={`https://highgroundsoftware.com/dash/dashboard/?des=10301&r=${item.id}&s=qtd`}>${currency(item.QTD)}</NavLink></Flex>
          <Flex fontSize="12px"><NavLink to={`https://highgroundsoftware.com/dash/dashboard/?des=10301&r=${item.id}&s=ytd`}>${currency(item.YTD)}</NavLink></Flex>
          </Flexed>
        

      })  
    }
    <Flexed padding="8px 0px"  className="hand" color="black" bgColor="rgb(0,0,0,.1)" onClick={() => window.open('https://cfp.highgroundsoftware.com/', '_blank')} >
    <Flex textAlign="left"  flex="1.5">CFP</Flex>
    <Flex >${currency(cfpsales?.today)}</Flex>
    <Flex >${currency(cfpsales?.week)}</Flex>
    <Flex >${currency(cfpsales?.lastweek)}</Flex>
    <Flex >${currency(cfpsales?.month)}</Flex>
    <Flex >${currency(cfpsales?.quarter)}</Flex>
    <Flex >${currency(cfpsales?.year)}</Flex>
</Flexed>
    </Scroll> 
    : <p style={{padding:"20px 0px",fontSize:"var(--headerLarge)",color:"rgb(255,0,0,.6)"}}>LOADING...</p>
}
    <Flexed className=""  bgColor="#0E425A" color="white"  padding="5px 0px">
          <Flex fontSize="12px"  flex="1.5"></Flex>
          <Flex fontSize="12px">${currency(todaytotals())}</Flex>
          <Flex fontSize="12px" >${currency(thisweektotals())}</Flex>
          <Flex fontSize="12px"> ${currency(lastweektotals())}</Flex>
          <Flex fontSize="12px">${currency(monthtodatetotal())}</Flex>
          <Flex fontSize="12px">${currency(quartertodatetotal())}</Flex>
          <Flex fontSize="12px">${currency(yeartodatetotals())}</Flex>
          </Flexed>
       
       
    </>
  )
}

export default Salesdetails



